export default function Navbar() {
    return(
        <>
            <header className="section rd-navbar-wrap">
                <nav className="rd-navbar context">
                    <div className="navbar-container">
                    <div className="navbar-cell">
                        <div className="navbar-panel">
                        <button
                            className="navbar-switch"
                            data-multi-switch='{"targets":".rd-navbar","scope":".rd-navbar","isolate":"[data-multi-switch]"}'
                        />
                        <div className="navbar-logo">
                            <a className="navbar-logo-link" href="index.html">
                            <img
                                className="navbar-logo-default"
                                src="images/logo-default-113x30.svg"
                                alt="Neon"
                                width={113}
                                height={30}
                                loading="lazy"
                            />
                            <img
                                className="navbar-logo-inverse"
                                src="images/logo-inverse-112x30.svg"
                                alt="Neon"
                                width={112}
                                height={30}
                                loading="lazy"
                            />
                            </a>
                        </div>
                        </div>
                    </div>
                    <div className="navbar-spacer" />
                    <div className="navbar-cell navbar-sidebar">
                        <ul className="navbar-navigation rd-navbar-nav fullpage-navigation">
                        <li
                            className="navbar-navigation-root-item active"
                            data-menuanchor="home"
                        >
                            <a className="navbar-navigation-root-link" href="#home">
                            Home
                            </a>
                            <ul className="navbar-navigation-dropdown rd-navbar-dropdown">
                            <li className="navbar-navigation-back">
                                <button className="navbar-navigation-back-btn">Back</button>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="index.html"
                                >
                                Home
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="index-2.html"
                                >
                                Home #2
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="index-3.html"
                                >
                                Home #3
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item active">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="index-4.html"
                                >
                                Home #4
                                </a>
                            </li>
                            </ul>
                        </li>
                        <li
                            className="navbar-navigation-root-item"
                            data-menuanchor="features"
                        >
                            <a className="navbar-navigation-root-link" href="#features">
                            Features
                            </a>
                        </li>
                        <li className="navbar-navigation-root-item" data-menuanchor="pricing">
                            <a className="navbar-navigation-root-link" href="#pricing">
                            Pricing
                            </a>
                        </li>
                        <li
                            className="navbar-navigation-root-item"
                            data-menuanchor="contacts"
                        >
                            <a className="navbar-navigation-root-link" href="#contacts">
                            Contacts
                            </a>
                        </li>
                        <li className="navbar-navigation-root-item" data-menuanchor="">
                            <a className="navbar-navigation-root-link" href="#">
                            Pages
                            </a>
                            <ul className="navbar-navigation-dropdown rd-navbar-dropdown">
                            <li className="navbar-navigation-back">
                                <button className="navbar-navigation-back-btn">Back</button>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="404-page.html"
                                >
                                404 page
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="coming-soon.html"
                                >
                                Coming soon
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="elements.html"
                                >
                                Elements
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a className="navbar-navigation-dropdown-link" href="faq.html">
                                FAQ
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="privacy-policy.html"
                                >
                                Privacy Policy
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="testimonials.html"
                                >
                                Testimonials
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="testimonials-2.html"
                                >
                                Testimonials 2
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="single-career.html"
                                >
                                Single career
                                </a>
                            </li>
                            <li className="navbar-navigation-dropdown-item">
                                <a
                                className="navbar-navigation-dropdown-link"
                                href="single-post.html"
                                >
                                Single post
                                </a>
                            </li>
                            </ul>
                        </li>
                        </ul>
                    </div>
                    <div className="navbar-cell">
                        <div className="navbar-subpanel">
                        <div className="navbar-subpanel-item">
                            <button
                            className="navbar-button navbar-info-button mdi-dots-vertical"
                            data-multi-switch='{"targets":".rd-navbar","scope":".rd-navbar","class":"navbar-info-active","isolate":"[data-multi-switch]"}'
                            />
                            <div className="navbar-info">
                            <button
                                className="btn btn-sm"
                                data-modal-trigger='{"target":"#modal-login"}'
                            >
                                Get App
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </nav>
            </header>
        </>
    );
}