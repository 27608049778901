export default function Footer() {
    return(
        <>
            {/* Get in touch*/}
            <section className="section section-xl position-relative" id="contacts">
                <div className="bg-layer-2">
                <svg
                    className="image-svg"
                    width={1920}
                    height={792}
                    viewBox="0 0 1920 792"
                    fill="none"
                >
                    <path
                    d="M1305 120.5L-4.12635e-05 120.5L-5.1798e-05 6.10352e-05L1920 -0.000106817L1920 592.5L1510 592.5C1452.01 592.5 1405 545.49 1405 487.5L1405 220.5C1405 165.271 1360.23 120.5 1305 120.5Z"
                    fill="#DEEBF7"
                    />
                </svg>
                </div>
                <div className="container">
                <div className="row row-30 align-items-xl-center justify-content-xxl-between">
                    <div className="col-lg-6">
                    <h2>Get in Touch</h2>
                    <p className="bigger op-6">
                        Have a question about our app? Don’t hesitate to contact us directly
                        using the form on this page or by visiting our office.
                    </p>
                    <form
                        className="rd-mailform me-xxl-5"
                        data-form-output="form-output-global"
                        data-form-type="contact"
                        method="post"
                        action="bat/rd-mailform.php"
                    >
                        <div className="row row-30">
                        <div className="col-xs-6">
                            <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Your name *"
                                data-constraints="@Required"
                            />
                            </div>
                        </div>
                        <div className="col-xs-6">
                            <div className="form-group">
                            <input
                                className="form-control"
                                type="email"
                                name="email"
                                placeholder="Your e-mail address *"
                                data-constraints="@Email @Required"
                            />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                            <textarea
                                className="form-control"
                                name="question"
                                placeholder="Your question"
                                rows={5}
                                data-constraints="@Required"
                                defaultValue={""}
                            />
                            </div>
                        </div>
                        </div>
                        <div className="offset-sm group-40 d-flex flex-wrap flex-xs-nowrap align-items-center">
                        <button className="btn" type="submit">
                            Send Message
                        </button>
                        {/* Checkbox*/}
                        <div className="custom-control custom-checkbox">
                            <input
                            className="custom-control-input"
                            type="checkbox"
                            id="check1"
                            />
                            <label className="custom-control-label" htmlFor="check1">
                            Get weekly news and updates
                            </label>
                        </div>
                        </div>
                    </form>
                    </div>
                    <div className="col-lg-6 position-relative">
                    <div className="box px-xl-3 px-xxl-4">
                        <div className="row row-30">
                        <div className="col-md-6 col-lg-12 col-xl-6">
                            <h4>Get The App:</h4>
                            <div className="group-30 offset-sm">
                            <a className="btn btn-app ps-1" href="#">
                                <span className="btn-icon icon icon-round icon-sm icon-primary icon-style-3 mdi-apple" />
                                <span>Apple Store</span>
                            </a>
                            <a className="btn btn-app ps-1" href="#">
                                <span className="btn-icon icon icon-round icon-sm icon-primary icon-style-3 mdi-google-play" />
                                <span>Google Play</span>
                            </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-12 col-xl-6">
                            <h4>Contacts:</h4>
                            <table className="table table-sm table-responsive-xl table-no-bordered bigger">
                            <tbody>
                                <tr>
                                <td className="text-end op-6 ps-0 align-middle">Ph.</td>
                                <td className="biggest">
                                    <a className="link-inherit" href="tel:#">
                                    1-800-901-234
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td className="text-end op-6 ps-0">Mail.</td>
                                <td>
                                    <a className="link-inherit" href="mailto:#">
                                    info@demolink.org
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td className="text-end op-6 ps-0">Office.</td>
                                <td>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                        <hr className="divider" />
                        <div className="group-20 d-flex flex-wrap align-items-center justify-content-between">
                        <div>
                            {/* List social*/}
                            <ul className="list list-social">
                            <li className="list-social-item">
                                <a
                                className="icon icon-circle icon-md icon-style-1 mdi-facebook"
                                href="#"
                                />
                            </li>
                            <li className="list-social-item">
                                <a
                                className="icon icon-circle icon-md icon-style-1 mdi-twitter"
                                href="#"
                                />
                            </li>
                            <li className="list-social-item">
                                <a
                                className="icon icon-circle icon-md icon-style-1 mdi-youtube-play"
                                href="#"
                                />
                            </li>
                            </ul>
                        </div>
                        <div>
                            <p className="rights bigger">
                            <span>©&nbsp;</span>
                            <span className="copyright-year" />
                            <span>.&nbsp;</span>
                            <span>Neon</span>
                            <span>.&nbsp;</span>
                            <a href="privacy-policy.html">Privacy Policy</a>
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div
                className="form-output snackbar snackbar-secondary"
                id="form-output-global"
                />
            </section>
        </>
    );
}