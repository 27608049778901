import logo from './logo.svg';
import './App.css';
import './assets/css/bootstrap.css';
import './assets/css/fonts.css';
import './assets/css/style-2.css';
import Home from './pages/Home';
function App() {
  return (
    <>
    <Home />
    </>

  );
}

export default App;
