export default function Header() {
    return(
        <>
            <div className="preloader">
                <div className="preloader-inner">
                    <div className="preloader-dot" />
                    <div className="preloader-dot" />
                    <div className="preloader-dot" />
                    <div className="preloader-dot" />
                </div>
            </div>
        </>
    );
}